<template>
  <div class="homePage">
    <div class="homeBg">
      <img
        src="https://sc2.hexiaoxiang.com/web_static/new_hx_web/banner-newTeach-v2.jpg"
        alt
        class="home-img home-img--banner"
      />
    </div>
    <div class="homeBg">
      <div class="homeBox">
        <div class="homeContent">
          <h1>河小象六日写字体验包</h1>
          <p>每天15分钟，在家提升练字水平</p>
          <ul>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon1.png" />
              <span>科学专业的写字训练</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon1.png" />
              <span>1套科学练字方法</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon1.png" />
              <span>全程伴写服务</span>
            </li>
          </ul>
          <signUpBtn rgba="orange" v-on:click.native="OpenMask(1)"></signUpBtn>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/logo-v1.png"
          alt="河小象写字课，硬笔书法"
          class="home-img"
          style="width: 602px"
        />
      </div>
    </div>
    <!-- <div class="homeBg">
      <div class="homeBox">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home2.png"
          alt
          class="home-img"
        />
        <div class="homeContent">
          <h1>每日古诗文</h1>
          <p>每天5分钟，一年吃透300个古诗文名句名段</p>
          <ul>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon2.png" />
              <span>经典诵读AI智能打分</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon2.png" />
              <span>北师大、浙师大硕博士团队研发</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon2.png" />
              <span>分年级课程、原创古诗文动画剧、名师权威讲解</span>
            </li>
          </ul>
          <signUpBtn rgba="green" v-on:click.native="OpenMask(2)"></signUpBtn>
        </div>
      </div>
    </div> -->
    <!-- <div class="homeBg">
      <div class="homeBox">
        <div class="homeContent">
          <h1>同步写作300天</h1>
          <p>每日一句，每周一段，每月一篇</p>
          <ul>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon3.png" />
              <span>同步语文教材、贴合考纲</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon3.png" />
              <span>分年段、300节在线课程、26册配套实体教材</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon3.png" />
              <span>每月1次一对一写作精点评、限时赠送4次周点评</span>
            </li>
          </ul>
          <signUpBtn rgba="blue" v-on:click.native="OpenMask(3)"></signUpBtn>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home3.png"
          alt
          class="home-img"
        />
      </div>
    </div> -->
    <!-- <div class="homeBg">
      <div class="homeBox">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home5.png"
          alt
          class="scratch-img"
        />
        <div class="homeContent">
          <h1 class="scratchHead">河小象编程</h1> -->
          <!-- <p>3节编程启蒙课，锻炼孩子逻辑思维</p> -->
          <!-- <ul>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon2.png" />
              <span>AI 互动教学轻松学习</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon2.png" />
              <span>班主任1V1点评辅导</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon2.png" />
              <span>多学科融合全面发展</span>
            </li>
          </ul>-->
          <!-- <signUpBtn rgba="blue" v-on:click.native="OpenMask(3)"></signUpBtn> -->
          <!--<button class="download-electron" @click="downloadElectron">下载客户端</button>
        </div>
      </div>
    </div> -->
    <!-- <div class="homeBg">
      <div class="homeBox">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/home4.png"
          alt
          class="home-img"
        >
        <div class="homeContent readUpClass">
          <h1>经典名著阅读力</h1>
           <p>系统规划提升7大阅读能力</p>
          <ul class="readUp">
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon4.png">
              <span>8大经典名著讲解</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon4.png">
              <span>名著诵读AI智能评测提语感</span>
            </li>
          </ul>
          <signUpBtn rgba="purple" v-on:click.native="OpenMask(4)"></signUpBtn>
        </div>
      </div>
    </div>-->
    <!-- <div class="homeBg">
      <div class="homeBox">
        <div class="homeContent readUpClass">
          <h1>河小象美术</h1>
          <ul class="readUp">
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/yellow.png" />
              <span>专业师资在线1对1点评辅导</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/yellow.png" />
              <span>适合儿童生理心理发展特点</span>
            </li>
            <li>
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/yellow.png" />
              <span>引导启发式教学</span>
            </li>
          </ul>
          <signUpBtn rgba="yellow" v-on:click.native="OpenMask(5)"></signUpBtn>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/art.png"
          alt
          class="home-img"
        />
      </div>
    </div> -->
    <quickTool :downloadShow="showData"></quickTool>
    <!-- 弹窗 -->
    <!-- <div class="mask" v-if="showMask">
       <div class="mask-content">
           <h2>立即报名</h2>
           <p>打开微信扫描二维码，立即学习</p>
           <span class="mask-img">
              <img :src="imgUrl">
           </span>
           <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/mask-close.png" class="mask-close" @click="closeMask">
       </div>
    </div>-->
    <!-- 弹窗 -->
    <enterMask :showMask="open" :imgUrl="url" @onClose="open=false"></enterMask>
  </div>
</template>
<script>
import signUpBtn from "@/components/signUpBtn";
import quickTool from '@/components/quickTool';
import enterMask from '@/components/enterMask'
export default {
  data () {
    return {
      showData: false,
      showMask: false,
      open: false,//弹窗弹出
      url: '',//二维码地址
    };
  },
  components: {
    signUpBtn,
    quickTool,
    enterMask
  },
  watch: {
    $route (to, from) {
      this.open = false
    }
  },
  methods:{
    downloadElectron(){
       var agent = navigator.userAgent.toLowerCase();
          console.log(navigator.userAgent)
          var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
          if(isMac){

            window.location.href="https://xcx.alicdn2.hexiaoxiang.com/dl/%E6%B2%B3%E5%B0%8F%E8%B1%A1%E7%BC%96%E7%A8%8B.dmg"

          }else{
            window.location.href="https://xcx.alicdn2.hexiaoxiang.com/dl/%E6%B2%B3%E5%B0%8F%E8%B1%A1%E7%BC%96%E7%A8%8B.exe"
          }
    },
    OpenMask (flag) {
      this.open = true;
      if (flag == 1) {
        let source = localStorage.getItem("source")
        if (source != null) {
          if (source.indexOf("bdss") != -1) {
            this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hard-pen.png"
          } else {
            this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-write.png"
          }
        } else {
          this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-write.png"
        }
      } else if (flag == 2) {
        this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-poetry.png"
      } else if (flag == 3) {
        this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-componsition.png"
      } else if (flag == 4) {
        this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-read.png"
      } else if (flag == 5) {
        this.url = "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-art.png"
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.homePage {
  display: flex;
  align-items: center;
  flex-direction: column;
  .homeBg {
    width: 100%;
    display: flex;
    justify-content: center;
    &:nth-child(2n + 1) {
      background-color: #f6f6f9;
    }
    .home-img {
      &--banner {
        width: 100%;
      }
    }
    .homeBox {
      display: flex;
      height: 640px;
      align-items: center;
      width: 1120px;
      justify-content: space-between;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 10px;
      .scratch-img {
        width: 572px;
        margin-right: 60px;
      }
      .download-electron {
        background: linear-gradient(
          90deg,
          rgba(185, 223, 69, 1) 0%,
          rgba(112, 192, 74, 1) 100%
        );
        box-shadow: 0px 16px 36px 0px rgba(185, 223, 69, 0.32);
        width: 240px;
        height: 60px;
        cursor: pointer;
        border-radius: 30px;
        color: #fff;
        font-size: 26px;
        font-weight: bold;
        border: 0;
        outline: none;
        margin-top: 59px;
      }
      .homeContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 500px;
        .scratchHead {
          margin-bottom: 34px;
        }
        &.readUpClass {
          margin-left: 84px;
        }
        h1 {
          font-size: 44px;
          font-weight: bold;
          color: #444144;
          font-family: MicrosoftYaHei-Bold;
        }
        p {
          margin: 12px 0 34px;
          color: #444144;
          font-size: 26px;
        }
        ul {
          list-style: none;
          padding: 0;
          text-align: left;
          margin: 0;
          &.readUp {
            margin-top: 52px;
          }
          li {
            display: flex;
            align-items: center;
            line-height: 38px;
            img {
              margin-right: 10px;
              height: 18px;
            }
          }
        }
      }
    }
  }
  .mask {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 8;
    .mask-content {
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      height: 364px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 377px;
      top: 20%;
      h2 {
        color: #fa6400;
        font-size: 26px;
        font-weight: 500;
        margin-top: 24px;
      }
      p {
        font-size: 16px;
        color: #333333;
        margin: 12px 0 30px;
      }
      .mask-img {
        width: 188px;
        height: 188px;
        background-color: #d8d8d8;
        display: block;
        img {
          height: 100%;
        }
      }
      .mask-close {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>
