<template>
    <ul class="quickTool">
        <li class="weixin">
            <div class="weixinBtn">
                <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/webchat.png" alt="">
                <span>公众号</span>
            </div>
            <div class="weixin-qrcode" v-if="!isHxxBaby"><img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wxapp_code.jpg"></div>
        </li>
        <li class="servicer">
            <div class="serviceBtn">
                <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/consult.png" alt="">
                <span>咨询</span>
            </div>
             <div class="service-tel">
                 <p class="service-tit">客服热线：</p>
                 <p class="service-number">{{ isHxxBaby ? '15314683489' : '400-163-3686'}}</p>
            </div>
        </li>
          <li class="download"  v-if="downloadShow">
            <div class="downloadBtn">
                <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/download.png" alt="">
                <span>下载</span>
            </div>
            <div class="download-qrcode">
               <div class="download-list">
                    <div class="download-box">
                        <span class="download-title">微信小程序</span>
                        <span class="download-code">
                            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/mine.png">
                        </span>
                    </div>
                    <div class="download-box">
                        <span class="download-title">ios版</span>
                        <span class="download-code">
                            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/ios.png">
                        </span>
                    </div>
                    <div class="download-box">
                        <span class="download-title">安卓版</span>
                        <span class="download-code">
                             <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/android.png">
                        </span>
                    </div>
                    <div class="download-box">
                        <span class="download-title">iPad版</span>
                        <span class="download-code">
                             <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/ipad.png">
                        </span>
                    </div>
               </div>
            </div>
        </li>
        <!-- <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/gift.png" alt="">
            <span class="free-get">免费领课</span>
        </li> -->
    </ul>    
</template>
<script>
export default {
  data(){
    return{

    }
  },
  computed: {    
    isHxxBaby: function() {
      let host = window.location.host;
      return host.match('hxxbaby.com');
    }
  },
   props:{
       downloadShow:{
           type:Boolean

       }
   }
}
</script>
<style lang="scss" scoped>
.quickTool{
    width: 82px;
    border-radius: 20px;
    background:rgba(255,255,255,1);
    position: fixed;
    bottom: 13rem;
    right: 42px;
    padding: 18px 0;
    border: 1px solid #DDDDE2;
    box-sizing: border-box;
    z-index: 3;
    li{
        list-style: none;
        color: #888888;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:last-child):after{
            position: absolute;
            content: '';
            width: 58px;
            height: 1px;
            background-color: #DDDDE2;
            left: 12px;
            bottom: -15px;
        }
        span{
            font-size: 14px;
            text-align:center;
            display: block;
        }
        &:not(:last-child){
            margin-bottom: 34px;
        }
        &.weixin{
            position: relative;
            .weixinBtn{
                width: 100%;
                text-align: center;
                &:hover + .weixin-qrcode{
                display: block;
                }
            }
            .weixin-qrcode{
                width: 200px;
                height: 200px;
                border-radius: 20px;
                position: absolute;
                top: -10px;
                left: -240px;
                border: 1px solid #DDDDE2;
                display: none;
                background-color: #fff;
                box-shadow:0px 32px 50px 0px rgba(161,161,172,0.18);
                line-height: 200px;
                text-align: center;
                &::after{
                    content: '';
                    position: absolute;
                    border-width: 6px;
                    border-style:solid;
                    border-color:    transparent transparent transparent #fff; 
                    display:block;
                    right:-12px;
                    top:20px;
                }
                &::before{
                    content: '';
                    position: absolute;
                    border-width: 6px;
                    border-style:solid;
                    border-color:    transparent transparent transparent #DDDDE2; 
                    display:block;
                    right:-13px;
                    top:20px;
                }
                img{
                    width: 90%;
                    height:90%;
                    vertical-align: middle;
                }
            }
        }
        &.servicer{
            position: relative;
             .serviceBtn{
                width: 100%;
                text-align: center;
                &:hover + .service-tel{
                display: block;
                }
            }
            .service-tel{
                 width: 300px;
                border-radius: 20px;
                position: absolute;
                top: -10px;
                left: -360px;
                border: 1px solid #DDDDE2;
                display: none;
                background-color: #fff;
                box-shadow:0px 32px 50px 0px rgba(161,161,172,0.18);
                text-align: center;
                padding: 20px 10px;
                .service-tit{
                    font-size: 22px;
                    color: #515558;
                }
                .service-number{
                    font-size: 34px;
                    color: #50BBF9;
                    font-weight: bold;
                }
                &::after{
                    content: '';
                    position: absolute;
                    border-width: 6px;
                    border-style:solid;
                    border-color:    transparent transparent transparent #fff; 
                    display:block;
                    right:-12px;
                    top:20px;
                }
                &::before{
                    content: '';
                    position: absolute;
                    border-width: 6px;
                    border-style:solid;
                    border-color:    transparent transparent transparent #DDDDE2; 
                    display:block;
                    right:-13px;
                    top:20px;
                }
            }
        }
        &.download{
            position: relative;
            .downloadBtn{
                width: 100%;
                text-align: center;
                &:hover + .download-qrcode{
               display: block;
            }
            }
            .download-qrcode{
               background: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/downloadBg.png') no-repeat;
                width: 1078px;
                position: absolute;
                right: 70px;
                top: -122px;
                background-size: 1078px 368px;
                display: none;
                .download-list{
                    display: flex;
                    justify-content: center;
                    .download-box{
                    padding: 30px 0;
                    &:not(:last-child){
                        margin-right: 48px;
                    }
                    .download-title{
                        font-size: 22px;
                        color: #515558;
                        margin-bottom: 17px;
                    }
                    .download-code{
                        display: block;
                        width: 180px;
                        height: 180px;
                        border-radius: 16px;
                        background: #EEEEEE;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                }
                
            }
        }
    }
}
</style>
