
<template>
    <button class="signUpBtn" :class="rgba">
        <template v-if="rgba=='orange'">立即领取</template>
         <template v-if="rgba!='orange'">立即报名</template>
    </button>
</template>
<script>
export default {
    name:'signUpBtn',
    data(){
        return{
        }
    },
    props:{
         rgba: { // 按钮颜色
        type: String,
        required: true
        }
    }
}
</script>
<style lang="scss" scoped>
.signUpBtn{
    width: 240px;
    height: 60px;
    cursor: pointer;
    border-radius:30px;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    border: 0;
    outline: none;
    margin-top: 59px;
    &.orange{
        background:linear-gradient(270deg,rgba(248,120,51,1) 0%,rgba(255,139,53,1) 100%);
        box-shadow:0px 16px 36px 0px rgba(252,114,46,0.32);
    }
    &.green{
        background:linear-gradient(90deg,rgba(101,238,91,1) 0%,rgba(75,216,59,1) 100%);
        box-shadow:0px 16px 36px 0px rgba(77,218,62,0.32);
    }
    &.blue{
        background:linear-gradient(90deg,rgba(137,227,255,1) 0%,rgba(90,202,255,1) 100%);
        box-shadow:0px 16px 36px 0px rgba(90,202,255,0.32);
    }
    &.purple{
        background:linear-gradient(90deg,rgba(255,134,241,1) 0%,rgba(181,97,193,1) 100%);
        box-shadow:0px 16px 36px 0px rgba(186,59,204,0.32);
        margin-top: 93px;
    }
    &.yellow{
      background:linear-gradient(90deg,rgba(255,216,0,1) 0%,rgba(255,185,0,1) 100%);
      box-shadow:0px 16px 36px 0px rgba(255,186,0,0.32);
    }
}
</style>
