<template>
    <!-- 弹窗 -->
    <div class="mask" v-if="showMask" @touchmove.prevent>
       <div class="mask-content">
           <h2>立即领取</h2>
           <p>打开微信扫描二维码，立即领取</p>
           <span class="mask-img">
              <img :src="imgUrl">
           </span>
           <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/mask-close.png" class="mask-close" @click.stop="onClose">
       </div>
    </div>    
</template>
<script>
export default {
    props:{
        showMask: { //显示
        type: Boolean,
        required: true
        },
        imgUrl:{
            type:String,
            required:true
        }
    },
    data(){
        return{
        }
    },
    mounted(){
    //   if (this._isMobile()) {
    //   alert("手机端");
    //   this.$router.replace('/m_index');
    // } else {
    //   alert("pc端");
    //   this.$router.replace('/pc_index');
    // }
    },
    watch:{
      showMask:function(val){
          if (this._isMobile()) {
        } else {
           if(val){
            document.querySelector('body').setAttribute('style', 'position:fixed;width:100%')
            }else{
                document.querySelector('body').removeAttribute('style', 'position:fixed;width:100%')
            }
        }
      }
    },
    methods:{
        onClose(){
           this.$emit('onClose')
        },
        _isMobile() {
          let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
          return flag;
        }
    }
}
</script>
<style lang="scss" scoped>
 .mask{
    position: fixed;
    background:rgba(0,0,0,.6);
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 8;
    top: 0;
    left: 0;
    bottom: 0;
    .mask-content{
     border-radius: 20px;
    background-color: #fff;
    position: relative;
    height: 364px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 377px;
    top: 20%;
      h2{
        color: #FA6400;
        font-size: 26px;
        font-weight: 500;
        margin-top: 24px;
      }
      p{
        font-size: 16px;
        color: #333333;
        margin: 12px 0 30px;
      }
      .mask-img{
        height: 188px;
        background-color: #D8D8D8;
        display: block;
        img{
          width: 188px;
          height: 188px;
        }
      }
      .mask-close{
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
  }
</style>
